import { Component, OnInit, OnDestroy, signal } from '@angular/core';
import { ConnectivityService } from './shared/services/connectivity.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isOnline = signal<boolean>(true);
  isUserAuthenticated = signal<boolean>(false);
  // private readonly avCoreUrl: string = environment.avCore;

  private connectivitySubscription!: Subscription;
  // private tokenExpirationSubscription!: Subscription;

  constructor(
    private connectivityService: ConnectivityService,
    private messageService: MessageService,
    private router: Router
  ) {
    // for maintance
    // this.router.navigateByUrl('under-maintance');
  }

  ngOnInit() {
    document.addEventListener('DOMContentLoaded', () => {
      const initialOnlineStatus = this.connectivityService.isOnline();

      this.isOnline.set(initialOnlineStatus);
      if (!initialOnlineStatus) {
        this.showOfflineMessage();
      }
    });

    // Subscribe to online / offline events
    this.connectivitySubscription =
      this.connectivityService.isOnline$.subscribe((status) => {
        this.isOnline.set(status);

        if (!status) {
          this.showOfflineMessage();
        } else {
          this.messageService.clear('tl');
          this.showOnlineMessage();
        }
      });
  }

  ngOnDestroy() {
    if (this.connectivitySubscription) {
      this.connectivitySubscription.unsubscribe();
    }
  }

  private showOfflineMessage() {
    this.messageService.add({
      key: 'tl',
      severity: 'error',
      detail:
        'Es gibt keine Internetverbindung. Versuchen Sie es später noch einmal',
      summary: 'Offline',
      life: 200000,
      closable: false,
    });
  }

  private showOnlineMessage() {
    this.messageService.add({
      key: 'tl',
      severity: 'success',
      detail: 'Die Internetverbindung ist wiederhergestellt.',
      summary: 'Online',
      life: 5000,
      closable: true,
    });
  }
}