import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserModel } from './models/user.model';
import { ApiService } from '@app/shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginErrorSubject = new Subject<string>();
  private loginSuccessSubject = new Subject<boolean>();
  private userInfo$ = new Subject<UserModel>();
  private isLoggingOut = false;
  loginError$ = this.loginErrorSubject.asObservable();
  loginSuccess$ = this.loginSuccessSubject.asObservable();

  constructor(private router: Router, private http: HttpClient, private api: ApiService) {}

  login(username: string, password: string) {
    this.http
      .post(`${environment.avCore}/api/auth/login`, { username, password })
      .pipe()
      .subscribe(
        (response: any) => {
          const accessToken = response?.access_token;
          localStorage.setItem('authToken', JSON.stringify(response));
          this.loginSuccessSubject.next(true);

          if (accessToken) {
            localStorage.setItem('token', accessToken);
            const decodedToken = jwtDecode(accessToken);
            localStorage.setItem('auth', JSON.stringify(decodedToken));
            const storedUser = JSON.parse(localStorage.getItem('auth') || '{}');
            const roleRoutes: { [key: string]: string } = {
              sb: 'sb',
              sv: 'sv',
              admin: 'admin',
              'sv-einteiler': 'sv-einteiler',
            };
            storedUser.roles.forEach((role: string) => {
              if (roleRoutes[role]) {
                this.router.navigate([roleRoutes[role]]);
              }
            });

            sessionStorage.setItem(
              'auth',
              JSON.stringify({
                username: username,
                pws: password,
              })
            );
          }
        },
        (error) => {
          this.loginErrorSubject.next('Ungültige Berechtigungsnachweise');
          console.error(error.error.error);
        }
      );
  }


logout(): void {
    if (this.isLoggingOut) return; // Prevent multiple calls
    this.isLoggingOut = true;

    const auth = JSON.parse(localStorage.getItem('authToken') as string);

    this.api.post(`api/auth/logout`, {
      refreshToken: auth?.refresh_token
    }).pipe(finalize(() => {
      this.isLoggingOut = false; // Reset flag after API call
    })).subscribe(
      () => {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
      },
      err => {
        if(err.status === 200) {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    );
}


  public getRole() {
    const storage = localStorage.getItem('auth');
    const parsedUser = JSON.parse(storage as string);

    const roles = parsedUser.realm_access.roles;

    roles.forEach((role: any) => {
      return role;
    });
  }

  public getUserInfo() {
    const storage = localStorage.getItem('auth');
    const parsedUser = JSON.parse(storage as string);

    this.userInfo$.next(parsedUser);
  }
}
