import { Injectable, inject } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ApiService } from '@app/shared/services/api.service';
import { tap } from 'rxjs/operators';

export interface UserStateModel {
  user: any;
  initials: string;
  dashboards: string[];
  roles: string[];
  attributes: any;
  fullName: string;
}

export interface UsersStateModel {
  allUsers: any[]; // Fixed incorrect type
}

// Actions
export class GetUserDetails {
  static readonly type = '[User] Get Details';
}

export class GetAllUsers {
  static readonly type = '[Users] Get All Users';
}

export class LogoutUser {
  static readonly type = '[User] Logout';
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    allUsers: [],
  }
})

// ---------------------- User State ----------------------
@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    initials: '',
    dashboards: [],
    roles: [],
    attributes: {},
    fullName: '',
  }
})
@Injectable({ providedIn: 'root' })
export class UserState {
  private api = inject(ApiService);

  @Action(GetUserDetails)
  getUserDetails(ctx: StateContext<UserStateModel>) {
    const authStorage = localStorage.getItem('auth');
    if (!authStorage) {
      ctx.dispatch(new LogoutUser());
      return;
    }

    const parsedUser = JSON.parse(authStorage);
    if (!parsedUser?.sub) {
      ctx.dispatch(new LogoutUser());
      return;
    }

    return this.api.get(`api/users/${parsedUser.sub}`).pipe(
      tap({
        next: (response: any) => {
          const attributes = response.attribute?.attributes || {};
          const initials = attributes.short?.length ? attributes.short[0] : '';
          const dashboards = attributes.dashboards || [];
          const roles = response.roles || [];
          const fullName = `${response.firstName} ${response.lastName}`;

          localStorage.setItem('userLogedInUsername', JSON.stringify(initials));

          ctx.patchState({
            user: response,
            initials,
            dashboards,
            roles,
            attributes,
            fullName,
          });
        },
        error: (error) => {
          console.error('Error fetching user details:', error);
          ctx.dispatch(new LogoutUser());
        }
      })
    );
  }

  @Action(GetAllUsers)
  getAllUsers(ctx: StateContext<UsersStateModel>) {
    return this.api.get('api/users').pipe(
      tap({
        next: (users: any[]) => {
          ctx.patchState({ allUsers: users });
        },
        error: (error) => {
          console.error('Error fetching all users:', error);
        }
      })
    );
  }

  @Action(LogoutUser)
  logout(ctx: StateContext<UserStateModel>) {
    localStorage.clear();
    ctx.setState({
      user: null,
      initials: '',
      dashboards: [],
      roles: [],
      attributes: {},
      fullName: '',
    });
  }

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static getInitials(state: UserStateModel) {
    return state.initials;
  }

  @Selector()
  static getDashboards(state: UserStateModel) {
    return state.dashboards;
  }

  @Selector()
  static getRoles(state: UserStateModel) {
    return state.roles;
  }

  @Selector()
  static getAttributes(state: UserStateModel) {
    return state.attributes;
  }

  @Selector()
  static getFullName(state: UserStateModel) {
    return state.fullName;
  }

  @Selector()
  static getAllUsers(state: UsersStateModel) {
    return state.allUsers; // Fixed incorrect property reference
  }
}

