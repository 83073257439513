import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // Core URLs
  private readonly avCoreUrl: string = environment.avCore;
  private readonly avImportUrl: string = environment.avImport;

  // Cache for ongoing HTTP requests
  private requestCache = new Map<string, Observable<any>>();

  constructor(private httpClient: HttpClient) {}

  private buildHttpOptions(
    headers?: HttpHeaders | { [header: string]: string | string[] }
  ) {
    return { headers };
  }

  // Method to generate a cache key based on URL and parameters
  private generateCacheKey(url: string, params?: HttpParams): string {
    return `${url}?${params?.toString() || ''}`;
  }

  // GET request with caching using shareReplay
  get(
    url: string,
    params?: Record<string, any>,
    headers?: HttpHeaders
  ): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params || {} });
    const options = this.buildHttpOptions(headers);
    
    // Perform the HTTP request and cache the observable
    const request$ = this.httpClient
      .get<any>(`${this.avCoreUrl}/${url}`, { params: httpParams, ...options })
      .pipe(
        //shareReplay(1), // Replay the last emitted value to new subscribers
        map((response) => response)
      );

    return request$;
  }

  post(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    const options = this.buildHttpOptions(headers);
    return this.httpClient
      .post<any>(`${this.avCoreUrl}/${url}`, data, options)
      .pipe(map((response) => response));
  }

  postImport(url: string, data: any, headers?: HttpHeaders): Observable<string> {
    const options = this.buildHttpOptions(headers);
    return this.httpClient
      .post<any>(`${this.avImportUrl}/${url}`, data, options)
      .pipe(map((response) => response));
  }

  put(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    const options = this.buildHttpOptions(headers);
    return this.httpClient
      .put<any>(`${this.avCoreUrl}/${url}`, data, options)
      .pipe(map((response) => JSON.stringify(response)));
  }

  delete(url: string, headers?: HttpHeaders): Observable<string> {
    const options = this.buildHttpOptions(headers);
    return this.httpClient
      .delete<any>(`${this.avCoreUrl}/${url}`, options)
      .pipe(map((response) => response));
  }
}
